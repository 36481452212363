import Link from "next/link";

// import logoImagePC from "/public/assets/pc/logo.svg";
import styles from "./헤더_Desktop2.module.css";

import { 헤더_회원가입_Desktop2 } from "./헤더_회원가입_Desktop2";
import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import { 마켓봄_로고_Desktop } from "./마켓봄_로고_Desktop";

import { asx } from "./util/applyStyle";
import { useContext } from "react";
import { GrowthBookCTX, getValue } from "./util/GrowthBookCTX";
import { pipe } from "@mobily/ts-belt";

import Image from "next/image";
import EventBannerImagePC from "/public/assets/pc/images/pic/banner.png";

const as = asx(styles);

export const 헤더_Desktop2 = () => {
  const tracker = useTracker();
  const featureFlag = useContext(GrowthBookCTX);
  const simplifiedRegister = pipe(featureFlag, getValue("simplified-register"));

  return (
    <div className={as("header docked")}>
      <div style={{ display: "flex", backgroundColor: "#7264FA", height: "70px" }}>
        <div className={styles["content"]} style={{ justifyContent: "center" }}>
          <Link
            href={링크.추천_이벤트_링크}
            target="_blank"
          >
            <Image
              style={{width: "100%", height: "70px"}}
              src={EventBannerImagePC}
              alt="마켓봄 추천하면 100% 지급"
            />
          </Link>
        </div>
      </div>
      <div className={styles["content"]}>
        <div className={styles["contentLeft"]}>
          <마켓봄_로고_Desktop />
          <div className={styles["gnb"]}>
            <ul className={styles["naviagtionRow"]}>
              <li>
                <span className={styles["link"]}>서비스 소개</span>
                <div className={styles["depth2"]}>
                  <ul>
                    <li>
                      <Link
                        href={링크.마켓봄_자세히보기}
                        onClick={() =>
                          tracker.trackingClick({
                            depth1: "GNB",
                            depth2: "소개",
                            depth3: "마켓봄",
                          })
                        }
                      >
                        마켓봄
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={링크.마켓봄도매_자세히보기}
                        onClick={() =>
                          tracker.trackingClick({
                            depth1: "GNB",
                            depth2: "소개",
                            depth3: "마켓봄 도매",
                          })
                        }
                      >
                        마켓봄 도매
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link
                  href={링크.마켓봄_이용가이드}
                  className={styles["link"]}
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "GNB",
                      depth2: "이용가이드",
                    })
                  }
                >
                  이용가이드
                </Link>
              </li>
              {/* <li>
                <Link
                  href={링크.비대면바우처}
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "GNB",
                      depth2: "비대면바우처",
                    })
                  }
                  className={styles["link"]}
                >
                  비대면바우처
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

        <div className={styles["sign-area"]}>
          <div className={styles["signButtons"]}>
            <div className={styles["signInButton"]}>
              로그인
              <div className={styles["loginDetail"]}>
                <Link
                  href={링크.마켓봄_유통사}
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "GNB",
                      depth2: "로그인",
                      depth3: "유통사",
                    })
                  }
                >
                  유통사/매입처
                </Link>
                <Link
                  href={링크.마켓봄_거래처}
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "GNB",
                      depth2: "로그인",
                      depth3: "식당",
                    })
                  }
                >
                  거래처(식당)
                </Link>
                <Link
                  href={링크.마켓봄도매}
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "GNB",
                      depth2: "로그인",
                      depth3: "도매",
                    })
                  }
                >
                  마켓봄 도매
                </Link>
              </div>
            </div>
            <헤더_회원가입_Desktop2 />

            <Link
              className={styles["signUpButton"]}
              href={
                simplifiedRegister
                  ? 링크.마켓봄_간편가입_실험_링크_PC
                  : 링크.마켓봄_회원가입
              }
              onClick={() =>
                tracker.trackingClick({
                  depth1: "GNB",
                  depth2: "회원가입",
                  depth3: "마켓봄",
                  indicator: "회원가입",
                  scroll: window.scrollY,
                })
              }
            >
              <div className={as("overlay")} />
              <div className={as("signUpCatchPhrase")}>
                <span>{"우리 회사에 잘 맞을까?"}</span>
                <span>{"5초 만에 시작하기"}</span>
                <span>{"우리 회사에 잘 맞을까?"}</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
